<template>
    <section class="floating-section">
        <svg-icon v-if="!!isWhiteBorderTop" name="borderTopWhite"/>
        <h3 v-html="$t(title)" class="floating-section_title" />
        <div class="floating-section-elements">
            <slot></slot>
        </div>
    </section>
</template>

<script>
export default {
    props: ['title', 'isWhiteBorderTop']
}
</script>

<style lang="sass" scoped>
@import '@/pages/asections/floatingSection.sass'
</style>