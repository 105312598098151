<template>
    <section class="options-section">
        <div class="options-section-desc">
            <div class="options-section-desc-head"
                v-if="!!description.headtext" 
                v-html="$t(description.headtext)"
            >
                <span class="options-section-desc-head_bl"/>
            </div>
            <div class="options-section-desc-head_emphasis"
                v-if="!!description.headtextEmphasis" 
            >
                <h3 class="options-section-desc-head_emphasis--title">{{$t(description.headtextEmphasis)}}</h3>
                <img class="options-section-desc-head_emphasis--icon" :src="require(`@/assets/img/options/options-emphasis.svg`)" alt="">
            </div>
            <h3 v-if="check($t(description.maintext)) !== 1" v-html="$t(description.maintext)" class="options-section-desc-main_title"></h3>
            <p v-if="check($t(description.maintext)) !== 1" v-html="$t(description.bottomtext)" class="options-section-desc-bottom_subtitle"></p>
        </div>
        <div class="options-section-icons">
            <div v-for="(el, i) in icons"
                :key="i"
                class="options-section-icons_wrapper"
            >
                <div class="options-section-icons_item">
                    <img :src="require(`@/assets/img/options/${el.name}.svg`)" alt="">
                </div>
                <div v-html="$t(el.title)" class="options-section-icons_text" />
            </div>
        </div>
        <div v-if="!!description.footertext" class="options-section-desc-footer">
            <span class="options-section-desc-footer_tl"/>
            <h3 class="options-section-desc-footer_title">{{$t(description.footertext)}}</h3>
        </div>
        <span class="options-section-footer_bl"/>
    </section>
</template>

<script>
export default {
    props: ['icons', 'description', 'isBottomBlueLine'],
    methods: {
        check(value) {
            return (value.match(/\s*/g) || []).length
        }
    }
}
</script>

<style lang="sass">
@import '@/pages/asections/optionsSection.sass'
</style>